import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Home from '../components/Home';
import Data from '../components/others/Data';
import Events from '../components/Events';
import { Game } from '../components/Game';
import Opportunities from '../components/Opportunities';
import Privacypolicy from '../components/Privacypolicy';

import Join from '../components/Join';


const AppRouter = () => (
    <Router>
        <div>
            <Route path="/" component={Home} exact={true} />
            <Route path="/events" component={Events} exact={true} />
            <Route path="/opportunities" component={Opportunities} exact={true} />
            <Route path="/game" component={Game} exact={true} />
            <Route path="/join" component={Join} exact={true} />
            <Route path="/privacypolicy" component={Privacypolicy} exact={true} />
            <Route path="/dataextractor" component={Data} exact={true} />
        </div>
    </Router>
);

export { AppRouter as default};