import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "./Header";

const Events = () => {
  const [t, i18n] = useTranslation("global");


  return (
    <div>
      <img className="acuarela" src="./erv-splash.png" alt="acuarela"></img>
      <Header />
      <section>
        <h1>{t("events.label1")}</h1>
        <p className="subtitle2">Do you have an event where you are reimagining conservation?</p> 
        <p className="subtitle2">
          <strong>Let us know</strong>
        </p>
        <img src="./australia-event.jpeg" className="event-img"></img>

        <div className="events">
          
          <div className="event-title">
            <p className="subtitle2">Reimagining Conservation Forum - Working Together for Healthy Country</p>
            <p className="subtitle3">- Developing effective Indigenous and non-Indigenous partnerships for healthy Country</p>
          </div>
          <p className="event-subtitle">Invitation</p>
          <p className="event-text">
          We are inviting you to the Reimagining Conservation Forum - Working Together for Healthy Country. The Forum is a collaboration between the North Australian Indigenous Land and Sea Management Alliance <a href='https://aciunc.us20.list-manage.com/track/click?u=2f72ad63775438c8ad1222477&id=6654e8d75f&e=d4b31d5fbc' target='_blank'>(NAILSMA)</a> , Protected Areas Collaboration <a href="https://pacollaboration.org/" target='_blank'>(PAC)</a>, Australian Committee for IUCN <a href='https://www.aciucn.org.au/' target='_blank'>(ACIUCN)</a> and NSW <a href='https://www.nationalparks.nsw.gov.au/' target='_blank'>National Parks and Wildlife Service</a>. This First Nations-led Forum brings together First Nations and non-Indigenous leaders and practitioners involved in the policies and practices of land and sea management across Australia.
          </p>  
          <p className="event-subtitle">When & Where</p>
          <p className="event-text">
          <strong>Brisbane, Australia</strong>, November 2nd - 4th, 2022</p>

          <p className="event-subtitle">Format</p>
          <p className="event-text">
            Day 1 of the Forum is for a 30-person Focus Group of First Nations leaders in land and sea management, by invitation. A wider group representing a balance of First Nations and non-Indigenous people, will participate on days 2 and 3 (November 3-4)
          </p>

          <p className="event-subtitle">Aims</p>
          <p className="event-text">
          The Forum will provide a unique opportunity to:
            <li>Share what 'reimagining conservation' means to First Nations people and to non-Indigenous Australians, and to reflect on what a shared reimagining means for policy makers and land and sea managers. </li>
            <li>Hear and amplify the voices of First Nations people about whole-of-country management of cultural landscapes, hear where this is working well, and consider indicators of effective management for conservation on country based on cultural values and ‘western’ science.</li>
            <li>Reflect on current policy and program settings – what works and what needs to change?</li>            
            The Forum also connects to a new global IUCN - reimagining conservation initiative that promotes a culture of conservation and care for the planet based on challenging the status quo, addressing injustices, listening to diverse audiences, and together, reimagining a new way of caring and protecting the planet and each other. 
          </p>

          <p className="event-subtitle">Participants</p>
          <p className="event-text">
            Places at the Forum are limited. If you are interested in participating, please complete this <a href='https://www.aciucn.org.au/eoi-reimagining-conservation-forum' target='_blank'>Expression of Interest Form</a> and we will be in touch.
            First Nations Delegate Sponsorship and Forum Sponsorship Packages are currently available. There is also some support for panelists and presenters. For enquiries relating to financial support, please contact ACIUCN Director,  <a href='mailto:Kate Davey<director@aciucn.org.au>'>Kate Davey</a>
          </p>
          
          <p className="event-subtitle">Registration Fee</p>
          <p className="event-text">
            <div className="event-fee-table">
                <div>
                  <p>Full Registration</p>
                </div>
                <div>
                  <p>$495</p>
                </div>
                <div>
                  <p>Discounted Registration for ACIUCN Members and Associates</p>
                </div>
                <div>
                  <p>$350</p>
                </div>
            </div>
          </p>
          
        </div>

        
      </section>

      <img
        className="splashfooter"
        src="./erv-splashfooter.png"
        alt="splah at footer"
      ></img>

      <footer>
        <div className="copyright">
          <img src="./reimagine-logo-footer.png"></img>
          <p>{t("footer.copyright.label2")}</p>
        </div>

        <nav className="footernav">
          <ul>
            <li>
              <a href="#home">{t("footer.footernav.label1")}</a>
            </li>
            <li>
              <Link to="/privacypolicy" target="_blank">
                {t("footer.footernav.label2")}
              </Link>
            </li>
            <li>
              <a href="#">{t("footer.footernav.label3")}</a>
            </li>
            <li>
              <a href="#">{t("footer.footernav.label4")}</a>
            </li>
          </ul>
        </nav>

        <div className="footersocial">
          <a href="#">
            <i className="fab fa-facebook fa-2x"></i>
          </a>
          <a href="#">
            <i className="fab fa-youtube fa-2x"></i>
          </a>
          <a href="#">
            <i className="fab fa-twitter fa-2x"></i>
          </a>
        </div>
      </footer>
    </div>
  );
};

export { Events as default };
