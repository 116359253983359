import React from 'react'


const Vimeo = ({source}) => {

    return(
    <div className="vimeo">
        <iframe
            src={source}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            >

        </iframe>
    </div>
    );
}

export {Vimeo as default}