import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {useState} from 'react';

const Header = () => {

    const [t, i18n] = useTranslation("global");
    const [lang, setLang] = useState(i18n.language);

  function switchLanguage(newlang) {
    i18n.changeLanguage(newlang);
    localStorage.setItem("lang", newlang);
    window.location.reload();
  }

  return (
    <header>
      <img className="logo" src="./reimagine-logo.png" alt="logo"></img>
      <input type="checkbox" id="nav-toggle" className="nav-toggle"></input>
      <nav>
        <ul>
          <li>
            <Link to="/">{t("menu.home")}</Link>
          </li>
          <li>
            <Link to="/events">{t("menu.events")}</Link>
          </li>
          <li>
            <Link to="/opportunities">{t("menu.opportunities")}</Link>
          </li>
          <li>
            <a href="/game">{t("menu.game")}</a>
          </li>
          <li>
            <a href="/join">{t("menu.join")}</a>
          </li>
          {/* <li><a href="#listen">{t("menu.listen")}</a></li>
                    <li><a href="#discuss">{t("menu.discuss")}</a></li>
                    <li><a href="#imagine">{t("menu.imagine")}</a></li>
                    <li><a href="#act">{t("menu.act")}</a></li> */}
          <li>
            <select
              id="select_lang"
              value={lang}
              onChange={(e) => {
                setLang(e.target.value);
                switchLanguage(e.target.value);
              }}
            >
              <option value="en">En</option>
              <option value="es">Es</option>
              <option value="fr">Fr</option>
            </select>
          </li>
        </ul>
      </nav>
      <label htmlFor="nav-toggle" className="nav-toggle-label">
        <span></span>
      </label>
    </header>
  );
};

export { Header as default };