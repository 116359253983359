import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import Vimeo from "./others/Vimeo";

import Videoask from "./others/Videoask";
import Header from "./Header";

import { useTranslation } from "react-i18next";
import Wordcloud from "./others/Wordcloud";
import { useDatabase } from "reactfire";

import "firebase/auth";
import { useFirebaseApp } from "reactfire";
import Modal from "./modal";

const Home = () => {
  const [t, i18n] = useTranslation("global");
  const [lang, setLang] = useState(i18n.language);

  const main_video = new Map();

  main_video.set(
    "en",
    "https://player.vimeo.com/video/596099441?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=bfdc4c6c42"
  );
  main_video.set(
    "es",
    "https://player.vimeo.com/video/644489490?h=93bfbd5e33&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
  );
  main_video.set(
    "fr",
    "https://player.vimeo.com/video/644490195?h=603fcc8b3f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
  );

  // JS function to select options from list based on its value
  const setSelectLanguage = (elementId, value) => {
    let item = document.getElementById(elementId);
    item.value = value;
  };

  /* For the carousel  */
  const db = useDatabase();
  const firebase = useFirebaseApp();

  let sectionIndex = 0;

  useEffect(() => {
    const videos = [];

    const Login = async () => {
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(
            process.env.REACT_APP_USER_EMAIL,
            process.env.REACT_APP_USER_PASSWORD
          );

        db.ref(`data/${process.env.REACT_APP_USER_ID}/carousel`)
          .once("value")
          .then((snap) => {
            // retrieve all videos
            snap.forEach((childSnap) => {
              const obj = childSnap.val();
              if (obj.active === 1) videos.push(obj);
            });

            if (videos.length > 0) {
              // calculates the % of the portion slider that center the video when centered
              const videosIndexMax = videos.length - 1;
              const portionIndexVideo = 100 / videos.length;

              const controls = document.querySelector(".controls ul");
              const slider = document.querySelector(".slider");

              slider.style.width = `${videos.length * 100}%`;

              videos.forEach((vid, index) => {
                const newLi = document.createElement("li");
                if (index === 0) {
                  newLi.classList.toggle("selected");
                }
                controls.appendChild(newLi);

                const newSlide = document.createElement("div");
                newSlide.classList.toggle("slide");

                const newDivVimeo = document.createElement("div");
                newDivVimeo.classList.toggle("vimeo");

                const newIframe = document.createElement("iframe");
                newIframe.style.border = "none";
                newIframe.src = vid.source;

                const p1 = document.createElement("p");
                p1.classList.toggle("note-video");
                p1.textContent = vid.note;

                const p2 = document.createElement("p");
                p2.classList.toggle("note-author");
                p2.textContent = vid.author;

                slider.appendChild(newSlide);

                newSlide.appendChild(newDivVimeo);
                newSlide.appendChild(p1);
                newSlide.appendChild(p2);

                newDivVimeo.appendChild(newIframe);
              });

              const leftArrow = document.querySelector(".left");
              const rightArrow = document.querySelector(".right");

              const indicadorParents = document.querySelector(".controls ul"); // obtiene la referencia del UL donde estan los dots

              const setSelectedIndex = () => {
                document
                  .querySelector(".controls .selected")
                  .classList.remove("selected");
                indicadorParents.children[sectionIndex].classList.add(
                  "selected"
                );
              };

              //// hace un loop al array de la lista para agregar el cambio de vista en el carousel
              document
                .querySelectorAll(".controls li")
                .forEach((indicator, index) => {
                  indicator.addEventListener("click", () => {
                    sectionIndex = index;

                    setSelectedIndex();
                    indicator.classList.add("selected");

                    const strIndex = index * -portionIndexVideo;
                    slider.style.transform = `translate(${strIndex}%)`;
                  });
                });

              rightArrow.addEventListener("click", () => {
                sectionIndex < videosIndexMax
                  ? (sectionIndex = sectionIndex + 1)
                  : (sectionIndex = 0);
                const strIndex = sectionIndex * -portionIndexVideo;
                slider.style.transform = `translate(${strIndex}%)`;

                setSelectedIndex();
              });

              leftArrow.addEventListener("click", () => {
                sectionIndex < 1
                  ? (sectionIndex = videosIndexMax)
                  : (sectionIndex = sectionIndex - 1);
                const strIndex = sectionIndex * -portionIndexVideo;
                slider.style.transform = `translate(${strIndex}%)`;

                setSelectedIndex();
              });
            }
          });

        setSelectLanguage("select_lang", i18n.language);
      } catch (err) {
        alert(err);
      }
    };
    Login();

    // const modal = document.getElementById("modalWindow");
    // modal.style.display = "block";
  }, []);

  return (
    <div>
      
      <img className="acuarela" src="./erv-splash.png" alt="acuarela"></img>
      <Header />

      <div className="introsection" id="home">
        
        <h1>
          {t("home.home_logo.label1")}
          <span> {t("home.home_logo.label11")} </span>
          {t("home.home_logo.label12")}
        </h1>
        <Vimeo source={main_video.get(lang)} />
        <p>
          {t("home.home_textvideo.label1")}

          <strong>{t("home.home_textvideo.label2")}</strong>
          {t("home.home_textvideo.label3")}
          <strong> {t("home.home_textvideo.label4")} </strong>
          {t("home.home_textvideo.label5")}
          <strong> {t("home.home_textvideo.label6")} </strong>
          {t("home.home_textvideo.label7")}
          <strong> {t("home.home_textvideo.label8")}</strong>
          {t("home.home_textvideo.label9")}
        </p>
      </div>

      <img className="left-splash" src="./left-splash-background.png"></img>
      <img className="rigth-splash" src="./rigth-splash-background.png"></img>
      <section className="listen" id="listen">
        <h1>{t("listen.label1")}</h1>
        <img src="./listen-sign.png"></img>
        <div className="listen-text">
          <p className="subtitle3">{t("listen.label2")}</p>
          <p className="subtitle2">{t("listen.label3")}</p>
          <p className="footer-quote-text">{t("listen.label4")}</p>
          <p className="footer-quote">{t("listen.label5")}</p>
        </div>
      </section>

      <section className="carousel-section">
        <div className="carousel">
          <div className="slider">
            {/* // here the slide div's area inserted dinamically */}
          </div>

          <div className="controls">
            <span className="arrow left">
              <i className="fas fa-angle-left fa-4x"></i>
            </span>
            <span className="arrow right">
              <i className="fas fa-angle-right fa-4x"></i>
            </span>
            <ul>{/* // here the LI are generated dinamically */}</ul>
          </div>
        </div>
        <section>
          <p>{t("carousel.label1")}</p>
          <button onClick={(e) => (window.location.hash = "#contact")}>
            {t("carousel.label2")}
          </button>
        </section>
      </section>

      <img className="left-splash" src="./left-splash-background.png"></img>
      <section className="discuss" id="discuss">
        <h1> {t("discuss.label1")}</h1>
        <img src="./discuss-sign.png"></img>
        <div className="listen-text">
          <p>
            {t("discuss.label2")}
            <strong>{t("discuss.label3")}</strong>
            {t("discuss.label4")}
            <strong>{t("discuss.label5")}</strong>
            {t("discuss.label6")}
            <strong>{t("discuss.label7")}</strong>
            {t("discuss.label8")}
          </p>
          <p className="footer-quote-text">“{t("discuss.label9")}”</p>
          <p className="footer-quote">{t("discuss.label10")}</p>
        </div>
      </section>
      
      <section>
        <p className="subtitle1">{t("wordcloud.label1")}</p>
        <p className="subtitle2">
          <strong>{t("wordcloud.label2")}</strong>
        </p>
        <p className="subtitle2">{t("wordcloud.label3")}</p>

        <Wordcloud t={t} lang={lang} />
      </section>

      <img className="rigth-splash" src="./rigth-splash-background.png"></img>

      <section>
        <p>{t("wordcloud.label7")}</p>
        <button onClick={(e) => (window.location.hash = "#contact")}>
          {t("wordcloud.label8")}
        </button>
      </section>

      <section className="imagine" id="imagine">
        <h1>{t("imagine.label1")} </h1>
        <img src="./imagine-sign.png"></img>
        <div className="listen-text">
          <p>
            {t("imagine.label2")}
            <strong>{t("imagine.label3")}</strong>
            {t("imagine.label4")}
            <strong>{t("imagine.label5")}</strong>
            {t("imagine.label6")}
          </p>
          <p className="footer-quote-text">{t("imagine.label7")}</p>
          <p className="footer-quote">{t("imagine.label8")}</p>
        </div>
      </section>
      <section>
        <p className="subtitle2">
          <strong>{t("imagine.label9")}</strong>
        </p>
        <p className="subtitle2">{t("imagine.label10")}</p>
      </section>

      <section>
        <Videoask />
      </section>
      <section>
        <p>{t("imagine.label11")}</p>
        <button onClick={(e) => (window.location.hash = "#contact")}>
          {t("imagine.label12")}
        </button>
      </section>

      <section className="imagine" id="act">
        <h1>{t("act.label1")}</h1>
        <img src="./act-sign.png"></img>
        <div className="listen-text">
          <p>
            {t("act.label2")}
            <strong>{t("act.label3")}</strong>
            {t("act.label4")}
          </p>
          <p className="footer-quote-text">{t("act.label5")}</p>
          <p className="footer-quote">{t("act.label6")}</p>
        </div>
      </section>

      <img
        className="splashfooter"
        src="./erv-splashfooter.png"
        alt="splah at footer"
      ></img>

      <footer>
        <div className="copyright">
          <img src="./reimagine-logo-footer.png"></img>
          <p>{t("footer.copyright.label2")}</p>
        </div>

        <nav className="footernav">
          <ul>
            <li>
              <a href="#home">{t("footer.footernav.label1")}</a>
            </li>
            <li>
              <Link to="/privacypolicy" target="_blank">
                {t("footer.footernav.label2")}
              </Link>
            </li>
            <li>
              <a href="#">{t("footer.footernav.label3")}</a>
            </li>
            <li>
              <a href="#">{t("footer.footernav.label4")}</a>
            </li>
          </ul>
        </nav>

        <div className="footersocial">
          <a href="#">
            <i className="fab fa-facebook fa-2x"></i>
          </a>
          <a href="#">
            <i className="fab fa-youtube fa-2x"></i>
          </a>
          <a href="#">
            <i className="fab fa-twitter fa-2x"></i>
          </a>
        </div>
      </footer>
    </div>
  );
};

export { Home as default };
