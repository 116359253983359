import React from 'react';

const Privacypolicy = () => {
    return (
    <section>
        <h1>Privacy Policy</h1>

        <p>
            IUCN, International Union for Conservation of Nature and Natural Resources with its headquarters located at Rue Mauverney 28, 1196, Gland Switzerland ("IUCN" or “we” or “us”) as the data controller is responsible for the processing of your personal information.
        </p>
        <p>
            IUCN is committed to protecting your privacy and would like to ensure that you get information, content and experiences that matter to you the most. This privacy policy applies to all personal information processed by IUCN.
        </p>
        <p>
            We invite you to carefully read this privacy policy which explains what personal information we collect, hold or process about you, your rights and obligations and how you can enforce them.
            </p>

    <h2>From whom do we collect personal information?</h2>
        <p>
        We may collect personal information from our Member representatives, Council and Commission members, staff, partners, donors, visitors, volunteers, event participants, service providers, consultants, newsletter subscribers, journalists and other IUCN contacts.
        </p>
        <p>
        When we collect or solicit personal information from anyone under the age of 18 or allow such persons to provide us with their personal information we do so only with parent or legal guardian consent. If you are under 18 years, do not send any information about yourself to us, including your name, address, telephone numbers, or email address, unless you have your parent's or legal guardian’s permission. In the event we learn that we collected personal information from anyone under the age of 18, and do not have a parent or legal guardian’s consent, we will delete that information as quickly as possible. If you believe that we might have any information from or about anyone under the age of 18, or if you wish to remove or request and obtain removal of content and information, please contact us using this online form.
        </p>
    <h2>What personal information do we collect and process about you?</h2>
        <p>
        In certain circumstances, you give us information such as when you create an account on IUCN systems, fill forms on IUCN sites, subscribe for IUCN newsletters, register for events, visit IUCN premises, correspond with us by phone or email or otherwise. The information you give us may include your designation, name(s), gender, physical address, email address, telephone number, mobile number, date of birth, nationality, country of residence, personal description, biography, photograph, ID or passport information, language preference, job title, position, organization, type of organization, areas of expertise, role, emergency contact information, name and contact information of accompanying person(s), payment details, bank account details, credit card information or information relating to your work experience and job application details.
        </p>
        <p>
        We automatically collect the following information from your visits to IUCN sites:
        </p>
        <ul>
            <li>technical information, including the Internet protocol (IP) address used to connect your computer or mobile device to the Internet, online identifiers, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform; and</li>
            <li>information about your visit, including the full Uniform Resource Locators (URL), clickstream to, through and from IUCN sites (including date and time).</li>
            <li>In other circumstances you provide us with your personal information such as your names, address, email address, telephone and mobile number etc., when you apply for a Job, apply to become a Council or Commission Member, are invited to be an Event speaker or are a nominated candidate . As a potential IUCN Member applicant, you also provide us with the personal information of your representative(s).</li>
        </ul>
    
    <h2>How does IUCN use your personal information?</h2>
    <p>
    We use your personal information for legitimate purposes determined when the personal information is collected to:
    </p>
    <ul>
        <li>process your application for example to become an IUCN Member, Council or, Commission member or staff member;</li>
        <li>carry out any obligations from any contracts entered into between you and us and provide you with information and services that you request from us;</li>
        <li>subscribe you to an IUCN newsletter or publication;</li>
        <li>process any donation you make;</li>
        <li>network with other participants;</li>
        <li>communicate with you about the IUCN World Conservation Congress, IUCN World Parks Congress as well as any other meetings, conferences or events (“Event”);</li>
        <li>register you for an Event;</li>
        <li>manage the Event and any related Event website;</li>
        <li>provide you with specific information, support or other requested services;</li>
        <li>assist in your participation in IUCN activities;</li>
        <li>get feedback or input from you;</li>
        <li>conduct surveys about your opinion of current services or of potential new services that may be offered;</li>
        <li>for any other legitimate business interest or interactions that you may have with IUCN.</li>

    </ul>
      <p>
        Newsletters: Prior to sending you newsletters, we ensure that we have obtained your consent or have an existing legitimate interest that allows us to process your personal information.
      </p>
      <p>
        Commercial use: We do not sell, rent or lease your personal information to third parties nor make commercial use of personal information.
    </p>
    
    <h2>Who has access to your personal information and with whom is it shared?</h2>

        <p>
        We may provide your personal information to other IUCN offices in other regions, IUCN Members, Council members, Commission members, IUCN National and Regional Committees and Partners for legitimate business purposes in compliance with this privacy policy and any other appropriate confidentiality and security measures.
        </p>
        <p>
        We may share your personal information with other Event participants provided you have consented to such sharing.
        </p>
        <p>
        For candidates nominated for elections as well as IUCN Council members and speakers at Events, we may share part of your personal information with the general public via the respective Event or IUCN website. This may include the following personal information: your first name, last name, job title, photo, organisation, nationality and biography.
        </p>
        <p>
        We may share your personal information with service providers or suppliers. These service providers or suppliers are bound by contractual agreements to keep your personal information private and secure.  The personal information you provide us with or we collect from you, may be transferred to service providers or suppliers located in countries outside of Switzerland and the European Economic Area (“EEA”). It may also be processed by staff or consultants operating outside of Switzerland and the EEA who work for us or for one of our suppliers. International organizations or some countries outside of Switzerland and the EEA may not offer adequate level of protection of personal information. If we transfer your personal information to such international organizations or countries, we will make sure that appropriate safeguards to protect your personal information are put in place.
        </p>
        <p>
        We will only otherwise disclose your personal information to third parties if we are under a duty to disclose or share your personal information to comply with any legal obligation, to enforce an existing contractual obligation or to protect the property, rights or safety of IUCN, our stakeholders, or others.
        </p>
    
    <h2>What are your rights and how can you exercise them?</h2>
        <p>
        You can ask to access, rectify or delete the personal information you have provided to IUCN. You may also request to be informed how your personal information is being processed, object to further processing or make a request to restrict processing. If you request IUCN to delete your personal information or restrict further processing, please note that you may not be able to benefit from the services we provide.
        </p>
        <p>
        Where applicable, you may also request to receive your personal information in a structured, commonly used, machine-readable format and if technically feasible ask that your personal information be transmitted directly to another controller. In such cases, IUCN reserves the right to request a reasonable fee in order to deal with the request.
        </p>
        <p>
        You may withdraw your consent at any time where processing is based on consent. If you withdraw your consent or ask that your personal information be deleted, IUCN will delete the information except where there exists another legal basis of processing such as:
        </p>
        <ul>
            <li>to comply with a legal obligation;</li>
            <li>to comply with a contractual obligation;</li>
            <li>for the legitimate interest of IUCN; or</li>
            <li>in the performance of a task carried out in the public interest or in the exercise of official authority vested in IUCN.</li>
        </ul>
        <p>
        If you have subscribed to an IUCN newsletter, you may ask not to continue to receive the newsletter by unsubscribing from the newsletter or by contacting us via the online form. You may also choose or manage the IUCN newsletter content you would like to receive here.
        </p>
        <p>
        If you have a user account, you may control your personal information by accessing the information through the account.
        </p>

    <h2>How long do we store personal information?</h2>

        <p>
        We keep your personal information for as long as it is necessary to fulfil the purpose for which it was collected or to comply with legal or regulatory requirements.
        </p>

    <h2>How do we protect your personal information?</h2>

        <p>
        We are committed to protecting the security of your personal information. Any personal information provided to us by you is handled with due care and security, and will not be used in ways other than as set forth in this privacy policy, or in any site/area-specific policies, or in ways to which you have not explicitly consented.
        </p>
        <p>
        All information you provide to us is stored on our secure servers. Any payment transactions are processed by third parties who process payments using accepted security standards. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our systems or sites, you are responsible for keeping this password confidential. We ask you not to share your password(s) with anyone.
        </p>
        <p>
        We are committed to ensuring a high level of protection of your personal information. We have put in place appropriate technical and organizational measures to protect the information maintained on our systems from loss, misuse, unauthorized access or disclosure, alteration or destruction. While personal information stored by IUCN is secure, we cannot guarantee the security of any information during transmission from a user-system to when the personal information arrives into our system.
        </p>
        <p>
        All IUCN employees, consultants and other IUCN external service providers who have access to your personal information or are involved in processing your personal information are obliged to respect the confidentiality of all such personal information and abide by applicable data protection principles.
        </p>

    <h2>Links to other sites</h2>
        <p>
        IUCN systems and sites may contain links to other external websites for your convenience and should not be construed as constituting any endorsement or approval of the external websites’ content. We take reasonable care in linking external websites but have no direct control over their content or the changes that may occur to the content on those external websites. It is your responsibility to check in each specific case if such websites secure your personal information from unauthorized access, use or disclosure.
        </p>
        <p>
        If you access external websites using the links provided, the operators of those websites may collect personal information from you that will be used by them in accordance with their own privacy policies. IUCN is not responsible for the privacy practices or the content of any such external websites. We encourage you to familiarize yourself with the privacy policies of any other websites that you choose to visit.
        </p>
    <h2>Cookies</h2>

        <p>
        We use cookies on some systems and sites for a number of purposes. Cookies help us to provide you with a good experience when you browse IUCN systems and sites and allow us to improve them. By continuing to browse IUCN systems and sites, you are agreeing to the use of cookies.
        </p>
       <p> 
        You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of the system or site(s) in question.
        </p>
    <h2>Sharing on social networks</h2>
        <p>
        From time to time, we may use social media platforms such as Facebook and Twitter to deliver targeted communications to accounts which have given consent to be contacted by advertisers. We do not obtain or store this personal information at any time. To change your settings on these social media platforms, please refer to the privacy settings section of the platform(s) in question.
        </p>
    <h2>Contact Us Here Only for questions related to data protection</h2>
        <p>
        If you wish to contact us regarding our use of your personal information or wish to exercise your rights or have any questions regarding this privacy policy please use this online form.
        </p>
    <h2>Changes to this privacy policy</h2>

        <p>This privacy policy was last updated on 14 June 2019.</p>



    </section>
    );
}

export { Privacypolicy as default };