
import React, { useEffect, useState } from 'react';
import ReactWordcloud from 'react-wordcloud';
 
import { useDatabase } from 'reactfire';


const Wordcloud = ( {t, lang, fetch}) => {


    const [word, setWord] = useState('');
    const [words, setWords] = useState([]);
 
    const db = useDatabase();

    const fetchUpdateState = (snap) => {
        const list = [];
        snap.forEach( (item) => {
                list.push({
                    text: item.key,
                    value: item.val().value
                });
                setWords(list);
        });
        
    }

    useEffect( ()=>{

        // load Word Cloud at component mount
        db.ref(`data/${process.env.REACT_APP_USER_ID}/wordcloud-v2/${lang}`).once('value').then( fetchUpdateState ).catch( (err) => {
            alert('error when loading word cloud',err);
        })
        
        /// Update chart when a word is added to the words array
        db.ref(`data/${process.env.REACT_APP_USER_ID}/wordcloud-v2/${lang}`).on('value', fetchUpdateState );
        
    },[])

   useEffect( ()=>{
        
        // db.ref('wordcloud').set(words).then( ()=>{

        // }).catch( (err)=>{
        //     alert('error:',err);
        // })

    },[word])


const onSubmit = (e) => {
    e.preventDefault();

    let countValue = 1;
    const wordEntry = word;
    
    // searchs for the specific word entry
    db.ref(`data/${process.env.REACT_APP_USER_ID}/wordcloud-v2/${lang}/${wordEntry}`)
        .once('value')                        
        .then( (snap)=>{
            // if found, then we need to update
            countValue = parseInt(snap.val().value) + 1;

            db.ref(`wordcloud-v2/${lang}/${wordEntry}`)
                .set({
                    value: countValue
                }).then( ()=>{
                    alert(t("wordcloud.label5"));
                    setWord('');
                }).catch( (err)=>{
                    alert(t("wordcloud.label6"),err);
                });

        }).catch( (err)=>{
                                    
            db.ref(`data/${process.env.REACT_APP_USER_ID}/wordcloud-v2/${lang}/${wordEntry}`)
                .set({
                    value: countValue
                    }).then( ()=>{
                        alert(t("wordcloud.label5"));
                        setWord('');
                    }).catch( (err)=>{
                        alert(t("wordcloud.label6"),err);
                    });                            
        });
  
}

  return (
    <div className="wordcloud">
        <form onSubmit={onSubmit}>
            <label htmlFor="myword">{t("wordcloud.label4")}</label>
            <input type="text" id="word" name="word" value={word} onChange={ e=> setWord(e.target.value)}></input>
            <input type="submit" value="Submit"></input>
        </form>
        <div className="chart">
            <ReactWordcloud words={words} />
        </div>
    </div>
  );
}

export { Wordcloud as default }