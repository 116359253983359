import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import AppRouter from './router/AppRouter';
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next';

import firebaseConfig from './firebase/firebase-config';
import { FirebaseAppProvider } from 'reactfire';

import './styles/styles.scss';

import global_en from "./translations/eng/global.json";
import global_es from "./translations/spa/global.json";
import global_fr from "./translations/fr/global.json";


let language = '';

const LoadLangFromLocal =  ()=> {
    const lang = localStorage.getItem('lang');
    
    if( lang ){
        language = lang

    } else {
        localStorage.setItem('lang','en');
        language = 'en'; // en, es, fr
    }

}

LoadLangFromLocal();

i18next.init({
    interpolation: { escapeValue: false },
    lng: language,
    resources: {
        es: {
            global: global_es
        },
        en: {
            global: global_en
        },
        fr: {
            global: global_fr
        }
    }
})


ReactDOM.render(
    <I18nextProvider i18n={i18next}>
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <Suspense fallback= {'Loading...'}>
                <AppRouter />
            </Suspense>
        </FirebaseAppProvider>
    </I18nextProvider>, document.getElementById('root'));


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
