import { useTranslation } from "react-i18next";
import Registration from "../components/Partners/Registration";
import Header from "./Header";

const Join = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <img className="acuarela" src="./erv-splash.png" alt="acuarela"></img>
      <Header />
      <section className="contact" id="contact">
        <h3>{t("contact.label1")}</h3>
        <div>
          <Registration t={t} />
        </div>
      </section>
    </div>
  );
};

export { Join as default };
