import React, { useEffect, useState } from 'react';

import 'firebase/storage';
import 'firebase/database';

import { useUser, useDatabase,useStorage } from 'reactfire';

import ProgressBar from './ProgressBar';

const Registration = ({t}) => {

    const [registerAs, setRegisterAs] = useState('org');
    const [name, setName] = useState('');
    const [orgName, setOrgName] = useState('');

    const [orgWebsite, setOrgWebsite] = useState('');
    const [instagram, setInstagram] = useState('');
    const [twitter, setTwitter] = useState('');
    const [facebook, setFacebook] = useState('');
    const [linkedIn, setLinkedIn] = useState('');
    const [youtube, setYoutube] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [position, setPosition] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [firstNameSec, setFirstNameSec] = useState('');
    const [lastNameSec, setLastNameSec] = useState('');
    const [positionSec, setPositionSec] = useState('');
    const [emailSec, setEmailSec] = useState('');
    const [phoneSec, setPhoneSec] = useState('');
    const [comments, setComments] = useState('');
    const [createdAt, setCreatedAt] = useState('today');
    const [member, setMember] = useState('no');

    let userAlreadyRegisted = false;

    //const { data: user } = useUser();
    const db = useDatabase();

    const [file, setFile ] = useState('');
    const [errorFile, setErrorFile] = useState('');

    const [progress, setProgress ] = useState('');
    const [errorupload, setErrorUpload ] = useState('');
    const [url, setUrl ] = useState('');

   
    function submitDataToFB(e) {

        e.preventDefault();

        const recordobject = {
                        registerAs,
                        name,
                        orgName,
                        member,
                        orgWebsite,
                        instagram,
                        twitter,
                        facebook,
                        linkedIn,
                        youtube,
                        firstName,
                        lastName,
                        position,
                        email,
                        phone,
                        firstNameSec,
                        lastNameSec,
                        emailSec,
                        phoneSec,
                        positionSec,
                        comments,
                        //createdBy: user.email,
                        createdAt }

            // flag that indicates that users already registerd
            if( !userAlreadyRegisted ) {
                 const newUserNodeRef = db.ref(`data/${process.env.REACT_APP_USER_ID}/register`);
                 const newUserRef = newUserNodeRef.push();
                 newUserRef.set({
                     ...recordobject
                 }).then( ()=> {
                     alert( t("contact.label2"));
                        setName('');
                        setOrgName('');
                 
                        setOrgWebsite('');
                        setInstagram('');
                        setTwitter('');
                        setFacebook('');
                        setLinkedIn('');
                        setYoutube('');
                 
                        setFirstName('');
                        setLastName('');
                        setPosition('');
                        setEmail('');
                        setPhone('');
                        setFirstNameSec('');
                        setLastNameSec('');
                        setPositionSec('');
                        setEmailSec('');
                        setPhoneSec('');
                        setComments('');
                 }).catch( (err) => {
                     alert(t("contact.label3"))
                 } )
            }
        

    }

    const storage = useStorage();

    useEffect( ()=>{
        
        if (!file) return;
        if( !email) return;

        const storageRef = storage.ref(`logos/${process.env.REACT_APP_USER_ID}/${email}/${file.name}`);
        storageRef.put(file).on('state_changed', (snap) => {
            let percentage = (snap.bytesTransferred /snap.totalBytes) * 100;
            setProgress(percentage);
            
    }, (err) => {
        setErrorFile(err);
    }, async () => {
        const url = await storageRef.getDownloadURL();
        setUrl(url);
    })
        
    },[file])


    const uploadFileHandler = (e) => {
        e.preventDefault();
        let selected = e.target.files[0];

        if( selected ){
            setFile(selected);
    
        } else {
            setFile('');
            setErrorFile( t("contact.label4") )
        }
            
    }    

    return (
        <form className="registration" onSubmit={submitDataToFB}>
                <div className="field">
                    <p> { t("contact.label5") }</p>
                    <input
                        id="registerasorg"
                        type="radio"
                        value="org"
                        name="registeras"
                        checked={ registerAs == 'org'}
                        onChange={ (e)=> setRegisterAs(e.target.value)}
                    >
                    </input> <label htmlFor='registerasorg'>{ t("contact.label6") }</label> 
                    <input
                        id="registerasindividual"
                        type="radio"
                        value="ind" 
                        name="registeras"
                        checked={ registerAs == 'ind'}
                        onChange={ (e)=> setRegisterAs(e.target.value)}
                    >
                    </input> <label htmlFor='registerasindividual'>{ t("contact.label7") }</label>
                </div>
                    <h3>{ t("contact.label8") }</h3>
                    <div className="field">
                        <label htmlFor="firstname">{ t("contact.label9") }</label>
                        <input type="text" id="firstname" required name="firstname" value={firstName} onChange={(e)=>setFirstName(e.target.value)}></input>
                    </div>
                    <div className="field">
                        <label htmlFor="lastname">{ t("contact.label10") }</label>
                        <input type="text" id="lastname" required name="lastname" value={lastName} onChange={(e)=>setLastName(e.target.value)}></input>
                    </div>
                    <div className="field">
                        <label htmlFor="contactoposition">{ t("contact.label11") }</label>
                        <input type="text" id="contactoposition" required name="contactoposition" value={position} onChange={(e)=>setPosition(e.target.value)}></input>
                    </div>
                    <div className="field">
                        <label htmlFor="contactemail">{ t("contact.label12") }</label>
                        <input type="email" id="contactemail" required name="contactemail" value={email} onChange={(e)=>setEmail(e.target.value)}></input>
                    </div>
                    <div className="field">
                        <label htmlFor="contactphone">{ t("contact.label13") }</label>
                        <input type="text" id="contactphone" required name="contactphone" value={phone} onChange={(e)=>setPhone(e.target.value)}></input>
                    </div>
                {
                registerAs == 'org' &&
                <div>
                    <div className="field">
                        <label htmlFor="name">{ t("contact.label14") }</label>
                        <input type="text" id="name" name="fstname" value={name} onChange={(e)=>setName(e.target.value)}></input>
                    </div>
                    <div className="field">
                        <label htmlFor="orgname">{ t("contact.label15") }</label>
                        <input type="text" id="orgname" name="orgname" value={orgName} onChange={(e)=>setOrgName(e.target.value)}></input>
                    </div>

                    <div className="field">
                        <label htmlFor="orglogo">{ t("contact.label16") }</label>
                        <p>{ t("contact.label17") }</p>
                        <input type="file" id="orglogo" onChange={uploadFileHandler} accept="image/png, image/jpeg, .ai, .eps" name="orglogo"></input>
                        { file && <div>{file.name} </div>}
                        { email && file && <ProgressBar progress={progress} file={file} setFile={setFile} url={url} /> }
                    </div>
                    <div className="field">
                        <label htmlFor="orgwebsite">{ t("contact.label18") }</label>
                        <input type="text" id="orgwebsite" name="orgwebsite" value={orgWebsite} onChange={(e)=>setOrgWebsite(e.target.value)} ></input>
                    </div>

                    <div className="field">
                    <p>{ t("contact.label19") }</p>
                    <p>{ t("contact.label20") }</p>
                    <input
                        id="member01"
                        type="radio"
                        value="yes"
                        name="member"
                        checked={ member == 'yes'}
                        onChange={ (e)=> setMember(e.target.value)}
                    >
                    </input> <label htmlFor='member01'>{ t("contact.label21") }</label>
                    <input
                        id="member02"
                        type="radio"
                        value="no" 
                        name="member"
                        checked={ member == 'no'}
                        onChange={ (e)=> setMember(e.target.value)}
                    >
                    </input> <label htmlFor='member02'>{ t("contact.label22") }</label>

                </div>

                </div>
                }               
                <div className="field">
                    <label htmlFor="orginstagram">{ t("contact.label23") }</label>
                    <input type="text" id="orginstagram" name="orginstagram" value={instagram} onChange={(e)=>setInstagram(e.target.value)}></input>
                </div>
                <div className="field">
                    <label htmlFor="orgtwitter">{ t("contact.label24") }</label>
                    <input type="text" id="orgtwitter" name="orgtwitter" value={twitter} onChange={(e)=>setTwitter(e.target.value)}></input>
                </div>
                <div className="field">
                    <label htmlFor="orgfacebook">{ t("contact.label25") }</label>
                    <input type="text" id="orgfacebook" name="orgfacebook" value={facebook} onChange={(e)=>setFacebook(e.target.value)}></input>
                </div>
                <div className="field">
                    <label htmlFor="orglinkedid">{ t("contact.label26") }</label>
                    <input type="text" id="orglinkedid" name="orglinkedid" value={linkedIn} onChange={(e)=>setLinkedIn(e.target.value)}></input>
                </div>
                <div className="field">
                    <label htmlFor="orgyoutube">{ t("contact.label27") }</label>
                    <input type="text" id="orgyoutube" name="orgyoutube" value={youtube} onChange={(e)=>setYoutube(e.target.value)}></input>
                </div>
                <h3>{ t("contact.label28") }</h3>
                <div className="field">
                    <label htmlFor="firstnamesec">{ t("contact.label29") }</label>
                    <input type="text" id="firstnamesec" name="firstnamesec" value={firstNameSec} onChange={(e)=>setFirstNameSec(e.target.value)}></input>
                </div>
                <div className="field">
                    <label htmlFor="lastnamesec">{ t("contact.label30") }</label>
                    <input type="text" id="lastnamesec" name="lastnamesec" value={lastNameSec} onChange={(e)=>setLastNameSec(e.target.value)}></input>
                </div>
                <div className="field">
                    <label htmlFor="contactopositionsec">{ t("contact.label31") }</label>
                    <input type="text" id="contactopositionsec" name="contactopositionsec" value={positionSec} onChange={(e)=>setPositionSec(e.target.value)}></input>
                </div>
                <div className="field">
                    <label htmlFor="contactemailsec">{ t("contact.label32") }</label>
                    <input type="email" id="contactemailsec" name="contactemailsec" value={emailSec} onChange={(e)=>setEmailSec(e.target.value)}></input>
                </div>
                <div className="field">
                    <label htmlFor="contactphonesec">{ t("contact.label33") }</label>
                    <input type="text" id="contactphonesec" name="contactphonesec" value={phoneSec} onChange={(e)=>setPhoneSec(e.target.value)}></input>
                </div>
                <div>
                    <label htmlFor="textjoinreimagine">{ t("contact.label34") }</label>
                    <textarea id="textjoinreimagine" placeholder={ t("contact.label35") } rows="10" cols="50" value={comments} onChange={(e)=>setComments(e.target.value)}></textarea>
                </div>
                
                <input type="submit" value={ t("contact.label36") }/>
        </form>
    );

}

export {Registration as default };