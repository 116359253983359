import React from 'react'


const Videoask = () => {
    return(
        <div>
            <iframe src="https://www.videoask.com/fsqfqfwgg" id="videoaskframe"
                allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                width="100%"
                height="600px"
            />

        </div>
    );
}

export {Videoask as default}