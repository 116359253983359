import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from './Header';
import { useTranslation } from "react-i18next";


const Opportunities = () => {

  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <img className="acuarela" src="./erv-splash.png" alt="acuarela"></img>
      <Header />
      <section>
        
        <h1>{t("opportunities.label1")}</h1>
        <p className="subtitle2">{t("opportunities.label2")}</p>

        
        <hr></hr>
        <div className="listen-text">
          <p className="subtitle2">&#127757; &#127793; Calling all Indigenous Youth! &#127793; &#127757;</p>
          <p className="subtitle3">&#128227; Join the Biannual UN Global Indigenous Youth Forum</p>
          <p className="subtitle3">&#128198; 16-20 October 2023 | &#128205; FAO HQ -Rome, Italy</p>
          
          <p>&#128275;
            Applications are now open for Indigenous Youth (aged 18-30) actively working on Indigenous Peoples’ food and knowledge systems in the context of climate action. If you're passionate about policy spaces related to these topics, <b>apply now!</b>
          </p>
          <p>&#x1F4E9;
            To apply, read and complete the application, and submit it to indigenous-peoples@fao.org by 30 June 2023. <a href='mailto:indigenous-peoples@fao.org' target="_blank">indigenous-peoples@fao.org</a> by <b>30 June 2023</b>.
          </p>
          <p>&#128279;
          Application package here: <a href='https://bit.ly/3CqIa1Y' target="_blank">https://bit.ly/3CqIa1Y</a> 
          </p>
          <p>&#127775;
            Don't miss out on this incredible opportunity to make a difference! 
          </p>
          {/* <p>
          &#127775; estrella
          &#128227; altavoz
          &#x1F4E9; inbox
          &#128275; lock
          &#128279; cadena
          &#128205; pin
          &#128198; calendar
          </p> */}
          
        </div>


      </section>

      <img
        className="splashfooter"
        src="./erv-splashfooter.png"
        alt="splah at footer"
      ></img>

      <footer>
        <div className="copyright">
          <img src="./reimagine-logo-footer.png"></img>
          <p>{t("footer.copyright.label2")}</p>
        </div>

        <nav className="footernav">
          <ul>
            <li>
              <a href="#home">{t("footer.footernav.label1")}</a>
            </li>
            <li>
              <Link to="/privacypolicy" target="_blank">
                {t("footer.footernav.label2")}
              </Link>
            </li>
            <li>
              <a href="#">{t("footer.footernav.label3")}</a>
            </li>
            <li>
              <a href="#">{t("footer.footernav.label4")}</a>
            </li>
          </ul>
        </nav>

        <div className="footersocial">
          <a href="#">
            <i className="fab fa-facebook fa-2x"></i>
          </a>
          <a href="#">
            <i className="fab fa-youtube fa-2x"></i>
          </a>
          <a href="#">
            <i className="fab fa-twitter fa-2x"></i>
          </a>
        </div>
      </footer>
    </div>
  );
};

export { Opportunities as default };
