import React, { useEffect } from 'react';

const ProgressBar = ({progress, url,setFile, file}) => {

    useEffect( ()=>{
        if( url ) {
            setFile('');
        }
    },[url])

    return (
        <div>
            {
                file ?
                <div className="progressbar">{ parseInt(progress) }%</div>
                : <p>Done!</p>
            }
        </div>
    );
}

export { ProgressBar as default};