import React, { useEffect, useState } from 'react';


import { useDatabase } from 'reactfire';

const Data = () => {

    const[data, setData] = useState([]);

    const db = useDatabase();

    useEffect( ()=>{
    
        db.ref(`data/${process.env.REACT_APP_USER_ID}/register`)
        .once('value')
        .then( snap => {
            const local = []
            snap.forEach( childSnap =>{
                local.push({
                    keyref: childSnap.key,
                    ...childSnap.val()
                })
            })
            setData(local);
            
        }).catch( (e) => alert('unable to load data..',e))

    },[])

    return (
        <section className="datarecords">

            {
                data.map( item => 
                <div key={item.id} className="dataregister">
                    <p><strong> ref: </strong>{item.keyref}</p>
                   { item.registerAs && <p><strong>register as: </strong>{item.registerAs}</p>}
                   { item.firstName && <p><strong>first name: </strong>{item.firstName}</p>}
                   { item.lastName && <p><strong>last name: </strong>{item.lastName}</p>}
                   { item.position && <p><strong>position: </strong>{item.position}</p>}
                   { item.email && <p><strong>email: </strong>{item.email}</p>}
                   { item.phone && <p><strong>phone number: </strong>{item.phone}</p>}
                   { item.name && <p><strong>name: </strong>{item.name}</p>}
                   { item.orgName && <p><strong>org name: </strong>{item.orgName}</p>}
                   { item.orgWebsite && <p><strong>org website: </strong>{item.orgWebsite}</p>}
                   { item.member && <p><strong>member of UICN: </strong>{item.member}</p>}
                   { item.instagram && <p><strong>instagram: </strong>{item.instagram}</p>}
                   { item.twitter && <p><strong>twitter: </strong>{item.twitter}</p>}
                   { item.facebook && <p><strong>facebook: </strong>{item.facebook}</p>}
                   { item.youtube && <p><strong>youtube: </strong>{item.youtube}</p>}
                  
                   { item.firstNameSec && <p><strong>first name: </strong>{item.firstNameSec}</p>}
                   { item.lastNameSec && <p><strong>last name: </strong>{item.lastNameSec}</p>}
                   { item.positionSec && <p><strong>position: </strong>{item.positionSec}</p>}
                   { item.emailSec && <p><strong>email: </strong>{item.emailSec}</p>}
                   { item.phoneSec && <p><strong>phone: </strong>{item.phoneSec}</p>}
                   { item.comments && <p><strong>comments: </strong>{item.comments}</p>}
                </div>)
            }

        </section>

    );
}

export { Data as default }