import Header from "./Header";

export const Game = () => {
  return (
    <div>
      <img className="acuarela" src="./erv-splash.png" alt="acuarela"></img>
      <Header/>
      <section>
        <p className="subtitle2">This part of our website is underconstruction!</p>
        <p className="subtitle3">Id non mollit cupidatat anim non esse pariatur aute. Est esse ea ipsum elit. Officia cupidatat anim duis dolore enim.

Quis consectetur culpa voluptate non sit cupidatat ipsum aliqua veniam. Qui nisi sunt occaecat ea tempor amet irure enim incididunt sint laboris. Officia in adipisicing est aliqua enim laboris. Veniam et deserunt quis deserunt deserunt eiusmod incididunt laboris veniam. Non reprehenderit veniam aliqua sit. Consectetur mollit duis anim proident qui.</p>
      </section>
    </div>
  );
};
